import React from "react"
// import { makeStyles } from '@material-ui/core/styles'

import { Box, Typography } from "@material-ui/core"
import SEO from "./seo"

import Headline from "./Headline"
import Navbar from "./Navbar"
import Wrapper from "./Wrapper"
import Heading from "./Heading"
import CountryList from "./CountryList"

import Footer from './Footer'

import MarketHeaderImage from "../images/market-header-img.png"

// const useStyles = makeStyles( theme => ({
//   headingWrapper:{
//     // backgroundPosition:'center',
//     [theme.breakpoints.down("md")]: {
//       backgroundPosition:'center'
//     }
//   }
// }))

const MarketRange = ({ pageContext }) => {
  const listDataNegara  = pageContext.listData
  // const classes = useStyles()
  // console.log(pageContext.listData)
  return (
    <Wrapper whatsappNumber={pageContext.footer.whatsappNumber} >
      <SEO title="Market Range" />

      <Headline />
      <Navbar />
      <Heading minHeight="13.625rem" background={(pageContext.bannerImg?pageContext.bannerImg:MarketHeaderImage)}>
        <Box
          className="heading-text-wrapper"
          width="100%"
          display="flex"
          alignItems="center"
        >
          <Box>
            <Typography variant="h1">{(pageContext.header?pageContext.header:"Market Range")}</Typography>
          </Box>
        </Box>
      </Heading>
      <Box
        className="section-country-list-wrapper"
        width="100%"
        display="flex"
        justifyContent="center"
        paddingY="5.625rem"
      >
        <Box
          className="country-list-wrapper"
          width="80%"
          display="flex"
          flexDirection="column"
        >
            {listDataNegara.map((countryData, index) =>
            <Box mb="100px" key={index}>
                <CountryList country={countryData}/>
            </Box>
            
            )}
        </Box>
      </Box>
      <Footer data={pageContext.footer}/>
    </Wrapper>
  )
}

export default MarketRange
