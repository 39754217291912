import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles( theme => ({
    root:{
        display:'flex',
        width:'100%',
        
    },
    countryWrapper:{
        [theme.breakpoints.down("md")]: {
            flexDirection:'column',
        }
    },
    countryList:{
        [theme.breakpoints.down("md")]: {
            width:'100%'
        }
    },
    countryListData:{
        [theme.breakpoints.down("md")]: {
            width:'25%'
        },
        [theme.breakpoints.down("sm")]: {
            width:'33.33%'
        }
    },
    countryName:{
        [theme.breakpoints.down("md")]: {
            width:'100%',
            marginBottom:'25px'
        }
    },
    continent:{
        fontSize:'30px'
    }
}))
export default useStyles