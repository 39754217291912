import React from 'react'
import { Box, Typography, Grid } from '@material-ui/core'

import useStyles from './style'


const CountryList = props => {
    const classes = useStyles()
    const { country } = props
    return (
        <Box className={classes.root}>
            <Box className={classes.countryWrapper} width="100%" display="flex">
                <Box className={classes.countryName} display="flex" width="25%">
                    <Typography variant="h2" className={classes.continent}>
                        { country.countryName }
                    </Typography>
                </Box>
                <Box className={classes.countryList} width="75%" display="flex" alignItems="center">
                    <Box display="flex" flexWrap="wrap" justifyContent="flex-start" width="100%">

                        {country.cityList.map((city, index) =>
                            <Box display="flex" width="20%" mb="16px" key={index} className={classes.countryListData}>
                                <Typography variant="body2">
                                    { city.name }
                                </Typography>
                            </Box>
                        
                        )}
                    </Box>
                </Box>
            </Box>
            {/* <Grid container>
                <Grid item xl={4} lg={4} md={4} sm={6} xs={6}>
                    <Typography variant="h2">
                        Africa
                    </Typography>
                </Grid>
                <Grid item xl={8} lg={8} md={8} sm={6} xs={6}>
                    <Grid container spacing={5}>
                        <Grid item xl={2} lg={5} md={5} sm={3} cs={3}>
                            Somalia
                        </Grid>
                        <Grid item xl={2} lg={5} md={5} sm={3} cs={3}>
                            Somalia
                        </Grid>
                        <Grid item xl={2} lg={5} md={5} sm={3} cs={3}>
                            Somalia
                        </Grid>
                        <Grid item xl={2} lg={5} md={5} sm={3} cs={3}>
                            Somalia
                        </Grid>
                        <Grid item xl={2} lg={5} md={5} sm={3} cs={3}>
                            Somalia
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> */}
        </Box>
    )
}

export default CountryList
